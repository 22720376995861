import React, { useState } from 'react';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@no-problem-net.com?subject=問い合わせ&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className={styles.contactForm}>
      <h2>お問い合わせ</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="message">お問い合わせ内容:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className={styles.textarea}
            rows="5"
          />
        </div>
        <button type="submit" className={styles.submitButton}>
          メーラーで開く
        </button>
      </form>
    </div>
  );
};

export default ContactForm;