import React from 'react';
import { Link } from 'react-router-dom';
import { Twitter } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Task Planner with AI</h3>
          <p>AIで最適化された計画立案を実現</p>
        </div>
        <div className="footer-section">
          <h3>クイックリンク</h3>
          <ul>
            <li><Link to="/">ホーム</Link></li>
            <li><Link to="/privacy-policy">プライバシーポリシー</Link></li>
            <li><Link to="/support">サポート</Link></li>
            <li><Link to="/terms-of-service">サービス利用規約</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>お問い合わせ</h3>
          <div className="social-icons">
            <a href="https://twitter.com/TaskPlannerAI" aria-label="Twitter">
              <Twitter />
              <span>@TaskPlannerAI</span>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {currentYear} Task Planner with AI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;