import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/AppPrivacyPolicy';
import SupportPage from './components/AppSupportPage';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import './styles/global.css';
import TermsOfService from './components/TermsOfService';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;