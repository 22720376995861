import React from 'react';
import { Brain, Target, Calendar, Zap } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="feature-card">
    <Icon size={32} className="feature-icon" />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const Features = () => {
  const features = [
    {
      icon: Brain,
      title: "AIによる計画作成",
      description: "あなたの願望から具体的な計画をAIが自動で作成します。"
    },
    {
      icon: Target,
      title: "目標達成をサポート",
      description: "具体的なタスクまでAIが提案し、計画倒れを防ぎます。"
    },
    {
      icon: Calendar,
      title: "スマートなスケジューリング",
      description: "AIがタスクのバランスを考慮し、最適なスケジュールを生成します。"
    },
    {
      icon: Zap,
      title: "リアルタイムの調整",
      description: "進捗に応じてAIが計画を柔軟に調整し、目標達成をサポートします。"
    }
  ];

  return (
    <section className="features" id="features">
      <div className="features-container">
        <h2>主な機能</h2>
        <div className="feature-grid">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;