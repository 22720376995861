import React from 'react';
// import logo from '../../assets/images/logo.jpg'; // ロゴ画像のパスを適切に設定してください

const Header = () => {
  return (
    <header className="header">
      {/* <div className="logo">
        <Link to="/">
          <img src={logo} alt="Task Planner with AI Logo" />
        </Link>
      </div> */}
      {/* <nav>
        <ul>
          <li><Link to="/">ホーム</Link></li>
          <li><Link to="/privacy-policy">プライバシーポリシー</Link></li>
          <li><Link to="/support">サポート</Link></li>
        </ul>
      </nav> */}
    </header>
  );
};

export default Header;