import React from 'react';
import Hero from './features/Hero';
import Features from './features/Features';
import Pricing from './features/Pricing';

const Home = () => {
  return (
    <div className="home">
      <main>
        <Hero />
        <Features />
        <Pricing />
      </main>
    </div>
  );
};

export default Home;