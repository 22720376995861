import React from 'react';
import { Check, Star, Gift, Download } from 'lucide-react';

const Alert = ({ children }) => (
  <div className="alert mb-8">
    {children}
  </div>
);

const PricingCard = ({ title, features, color, isPopular }) => (
  <div className={`pricing-card ${isPopular ? 'popular' : ''}`} style={{borderTopColor: color, borderTopWidth: '4px'}}>
    {isPopular && <span className="popular-tag">人気</span>}
    <h3>{title}</h3>
    <div className="price">
      <span className="text-3xl font-bold">完全無料</span>
      <span className="text-sm block">*キャンペーン期間中</span>
    </div>
    <ul className="features-list">
      {features.map((feature, index) => (
        <li key={index}>
          <Check size={18} />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const Pricing = () => {
  const plans = [
    {
      title: "ブロンズ",
      features: [
        "プロジェクトの最大数: 3",
        "全てのタスクの最大数: 100",
        "1日のAIの生成回数: 10",
        "生成AIのモデル: gemini 1.0-pro",
      ],
      color: "#CD7F32",
    },
    {
      title: "シルバー",
      features: [
        "プロジェクトの最大数: ∞",
        "全てのタスクの最大数: 1,000",
        "1日のAIの生成回数: 200",
        "生成AIのモデル: gemini 1.5-pro",
      ],
      color: "#C0C0C0",
    },
    {
      title: "ゴールド",
      features: [
        "プロジェクトの最大数: ∞",
        "全てのタスクの最大数: ∞",
        "1日のAIの生成回数: ∞",
        "生成AIのモデル: gemini 1.5-pro",
        "開発者に機能追加の提案",
      ],
      color: "#FFD700",
      isPopular: true,
    },
    {
      title: "プラチナ",
      features: [
        "プロジェクトの最大数: ∞",
        "全てのタスクの最大数: ∞",
        "1日のAIの生成回数: ∞",
        "生成AIのモデル: gemini 1.5-pro",
        "開発者に機能追加の提案",
        "テーマカラーのカスタマイズ",
        "アイコンの変更",
      ],
      color: "#E5E4E2",
    },
  ];

  const appStoreUrl = "https://apps.apple.com/jp/app/id6608970326";

  return (
    <section className="pricing" id="pricing">
      <div className="container">
        <h2>特別キャンペーン: 全プラン完全無料!</h2>
        <Alert>
          <div className="alert-title">
            <Gift className="h-5 w-5 mr-2" />
            リリース記念キャンペーン実施中
          </div>
          <p className="alert-description">
            期間限定で全てのプランを無料でお使いいただけます。この機会にTask Plannerアプリの全機能をお試しください！
          </p>
        </Alert>
        <p>今なら全ての機能が無料。あなたのニーズに合わせて最適なプランをお試しください。</p>
        <div className="pricing-grid">
          {plans.map((plan, index) => (
            <PricingCard key={index} {...plan} />
          ))}
        </div>
        <div className="cta-section mt-12 p-6 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg text-white shadow-lg">
          <p className="text-xl font-semibold mb-4">キャンペーン期間終了後も、ブロンズプランは永久無料でご利用いただけます。</p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
            <Star className="text-yellow-300" size={32} />
            <p className="text-2xl font-bold text-center sm:text-left">今すぐダウンロードして、全ての機能を無料で体験しよう！</p>
          </div>
          <a 
            href={appStoreUrl} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="cta-button mt-6 inline-flex items-center justify-center mx-auto"
          >
            <Download className="mr-2" size={20} />
            App Store でダウンロード
          </a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;