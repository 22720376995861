import React from 'react';
import styles from './TermsOfService.module.css';

const TermsOfService = () => {
  return (
    <div className={styles.termsOfService}>
      <h1>利用規約</h1>
      <p>最終更新日: 2024年9月21日</p>
      
      <section>
        <h2>1. はじめに</h2>
        <p>本利用規約（以下、「本規約」）は、Task Planner with AI（以下、「当アプリ」）の利用条件を定めるものです。ユーザーの皆様には、本規約に同意いただいた上で、当アプリをご利用いただきます。</p>
      </section>

      <section>
        <h2>2. サービスの内容</h2>
        <p>当アプリは、AIを活用したタスク管理および計画立案を支援するサービスを提供します。サービスの具体的な内容は、アプリ内で随時更新される場合があります。</p>
      </section>

      <section>
        <h2>3. アカウント</h2>
        <p>当アプリの利用にはアカウント登録が必要です。ユーザーは、正確かつ最新の情報を提供し、セキュリティのためにパスワードを適切に管理する責任があります。</p>
      </section>

      <section>
        <h2>4. 禁止事項</h2>
        <p>以下の行為を禁止します：</p>
        <ul>
          <li>法令または公序良俗に違反する行為</li>
          <li>当アプリのシステムに危害を加える行為</li>
          <li>他のユーザーに迷惑をかける行為</li>
          <li>当アプリの運営を妨害する行為</li>
        </ul>
      </section>

      <section>
        <h2>5. 知的財産権</h2>
        <p>当アプリに関する知的財産権は、当社または正当な権利者に帰属します。ユーザーは、これらの権利を侵害する行為を行ってはいけません。</p>
      </section>

      <section>
        <h2>6. 免責事項</h2>
        <p>当アプリは、サービスの完全性、正確性、有用性を保証するものではありません。当アプリの利用により生じた損害について、当社は一切の責任を負いません。</p>
      </section>

      <section>
        <h2>7. サービスの変更・停止</h2>
        <p>当社は、事前の通知なくサービスの内容を変更したり、提供を停止または中断したりする場合があります。</p>
      </section>

      <section>
        <h2>8. 規約の変更</h2>
        <p>当社は、必要に応じて本規約を変更することがあります。変更後の利用規約は、当アプリ上に掲載された時点で効力を生じるものとします。</p>
      </section>

      <section>
        <h2>9. 準拠法と管轄裁判所</h2>
        <p>本規約の解釈にあたっては、日本法を準拠法とします。本規約に関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      </section>

      <section>
        <h2>10. お問い合わせ</h2>
        <p>本規約に関するご質問やご意見は、以下の連絡先までお問い合わせください：</p>
        <p>メール: info@no-problem-net.com</p>
      </section>
    </div>
  );
};

export default TermsOfService;