import React from 'react';
import { Brain, Target, Zap } from 'lucide-react';
import appStoreBadge from '../../assets/images/appstore.png';

const Hero = () => {
  return (
    <section className="hero">
      <h1>Task Planner with AI</h1>
      <p className="subtitle">
        頑張らない、もう挫折しない生成AIと計画倒れを阻止する<br />
        最も楽な計画術
      </p>
      <p className="tagline">"頑張らない"の作り方</p>
      
      <div className="features-highlight">
        <div className="feature">
          <Brain size={24} />
          <p>AIが計画を作成</p>
        </div>
        <div className="feature">
          <Target size={24} />
          <p>具体的なタスクまで自動生成</p>
        </div>
        <div className="feature">
          <Zap size={24} />
          <p>計画倒れを大幅に防止</p>
        </div>
      </div>

      <div className="app-store-link">
        <a href="https://apps.apple.com/jp/app/id6608970326" target="_blank" rel="noopener noreferrer">
          <img 
            src={appStoreBadge} 
            alt="Download on the App Store" 
            width="200" 
            height="66"
          />
        </a>
      </div>
    </section>
  );
};

export default Hero;