import React from 'react';
import styles from './AppPrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>プライバシーポリシー</h1>
      <p>最終更新日: 2024年9月18日</p>
      
      <section>
        <h2>1. はじめに</h2>
        <p>Task Planner with AI（以下、「当アプリ」）は、ユーザーのプライバシーを尊重し、個人情報の保護に努めています。本プライバシーポリシーでは、当アプリが収集する情報とその使用方法について説明します。</p>
      </section>

      <section>
        <h2>2. 収集する情報</h2>
        <p>当アプリは、以下の情報を収集する場合があります：</p>
        <ul>
          <li>ユーザーが提供する情報（名前、メールアドレスなど）</li>
          <li>アプリの使用状況に関する情報</li>
          <li>デバイス情報（OSバージョン、デバイスモデルなど）</li>
        </ul>
      </section>

      <section>
        <h2>3. 情報の使用目的</h2>
        <p>収集した情報は、以下の目的で使用されます：</p>
        <ul>
          <li>アプリの機能提供と改善</li>
          <li>カスタマーサポート</li>
          <li>新機能や更新の通知</li>
        </ul>
      </section>

      <section>
        <h2>4. 情報の共有</h2>
        <p>当アプリは、法律で義務付けられている場合を除き、ユーザーの個人情報を第三者と共有することはありません。</p>
      </section>

      <section>
        <h2>5. データセキュリティ</h2>
        <p>当アプリは、ユーザーの情報を保護するために適切なセキュリティ対策を講じています。</p>
      </section>

      <section>
        <h2>6. ユーザーの権利</h2>
        <p>ユーザーは、自身の個人情報へのアクセス、訂正、削除を要求する権利を有しています。</p>
      </section>

      <section>
        <h2>7. プライバシーポリシーの変更</h2>
        <p>当アプリは、必要に応じてプライバシーポリシーを更新することがあります。重要な変更がある場合は、アプリ内で通知します。</p>
      </section>

      <section>
        <h2>8. お問い合わせ</h2>
        <p>プライバシーに関するご質問やご懸念がある場合は、以下の連絡先までお問い合わせください：</p>
        <p>メール: info@no-problem-net.com</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;